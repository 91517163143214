import React from "react";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";

const Footer = () => {
  return (
    <footer className="contact-footer">
      <div className="contact-content ">
        <div className="logo_box">
          <img
            src="/assets/images/guruji.png"
            alt="img"
            className="img-responsive"
          />
          <img src="/assets/images/footer-logo.png" alt="logo" />
        </div>

        <h5>
          {" "}
          <FiPhoneCall /> 91 87940 00011
        </h5>
        <div className="hr"></div>
        <div className="app_ui_box">
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://apps.apple.com/us/app/Bhrigu-jyotis/id6448903401"
            className="download-btn active"
          >
            <img src="/assets/images/app-store.png" alt="icon" />
            <span className="inner">
              <span className="avail">Available on</span>
              <span className="store-name">App Store</span>
            </span>
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://play.google.com/store/apps/details?id=com.brug.jyotis&amp;hl=en-IN"
            className="download-btn"
          >
            <img src="/assets/images/google-play.png" alt="icon" />
            <span className="inner">
              <span className="avail">Available on</span>
              <span className="store-name">Google play</span>
            </span>
          </Link>
        </div>
      </div>

      <p className="copy_pash">
        Copyright &copy; {new Date().getFullYear()} <span>BHRIGU JYOTIS</span>{" "}
        All Rights Reserved.
        <Link to="/privacypolicy" className="link_text">
          {" "}
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
